@import '../../../styles/globals/colors';

.stellar {
  .kyc-custom-button {
    &.btn {
      background-color: $stellar-button;
    }
    &.btn-secondary {
      background-color: $stellar-button !important;
      &:not([disabled]):not(.disabled):active,
      &:not([disabled]):not(.disabled).active,
      .show > &.dropdown-toggle {
        background-color: adjust-hue($stellar-button, 10%) !important;
      }
    }
  }
}

.binance {
  .kyc-custom-button {
    %binance_extend_1 {
      cursor: not-allowed;
      opacity: 0.5;
      box-shadow: none;
    }

    &.btn {
      &:hover {
        opacity: 0.6;
      }
      &:active {
        opacity: 0.6;
      }
      color: $dark-text !important;
      background: linear-gradient(90deg, $color_corn_approx 0, $color_bright_sun_approx 100%);
      border: 0;
      box-shadow: 0 10px 20px 0 $color_corn_30_approx;
      &:disabled {
        @extend %binance_extend_1;
        box-shadow: none !important;
        &:hover {
          @extend %binance_extend_1;
        }
      }
    }
    &.btn-secondary {
      background: linear-gradient(90deg, $color_corn_approx 0, $color_bright_sun_approx 100%) !important;
      &:not([disabled]):not(.disabled):active,
      &:not([disabled]):not(.disabled).active,
      .show > &.dropdown-toggle {
        background-color: adjust-hue($stellar-button, 10%) !important;
      }
    }
  }
}

.kyc-custom-button {
  &.btn {
    font-size: 16px;
    margin-left: 0;
    margin-right: 0;
    text-transform: none;

    padding: 10px 30px;
    border-radius: 12px;
    min-height: 3rem;
    font-weight: 500;
    font-size: 1rem;
    box-shadow: none;

    &:disabled {
      background: #f2f2f2 !important;
      color: #ccc !important;
    }

    &.loading {
      background-color: $dodger-blue !important;
    }
  }
  &.btn-secondary {
    background-color: $dodger-blue !important;
    border: 0 none;
    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: adjust-hue($dodger-blue, 10%) !important;
    }
  }
}
