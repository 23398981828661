.desc-lh-20 {
    line-height: 20px;
}

.desc-lh-16 {
    line-height: 16px;
}

.last-text-confirmation {
    margin-bottom: 128px !important;
    margin-top: 32px !important;
}
