.powered-by-settle {
  float: right !important;
}

@media (max-width: 767px) {
  .powered-by-settle {
    float: left !important;
    margin-right: 0px !important;
  }
}
