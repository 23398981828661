.loading {
  padding: 4rem;
}

.bank-data {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    text-align: start;
    margin-left: 24px;
    font-size: 14px;
    color: #6b6e70;

    p {
      line-height: 14px;
      margin: 0;
    }
  }
}

.bank-data-transfer {
  line-height: 16px;
  font-size: 16px;
  color: #070d10;
  margin-top: 24px;
}
