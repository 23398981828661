.simulateContainer {
  width: calc(41% - ((4%) * 0.5));
  display: block;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 8px 4px;
  padding: 20px 30px;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  height: auto;
  position: absolute;
  top: 10%;
  right: 10%;
  resize: horizontal;
}
.trigger-form {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2c2c;

  .label-container {
    left: 100%;
    bottom: 20px;
  }
  label {
    position: absolute;
    right: 30px;
    top: 20px;
  }
  input,
  select {
    width: 100%;
    margin: 5px 0;
    height: 50px;
    border: solid 2px #e6e6e6;
    border-radius: 5px;
    padding: 0 10px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2c2c2c;
  }
  input.currency {
    padding-right: 48px;
  }
  button {
    width: 100%;
    height: 50px;
    margin: 0.375rem 0;
  }
  .quote-value {
    padding: 10px 0 10px 10px;
    display: block;
    transition: opacity 0.2s, visibility 0.2s;
  }
  small {
    color: #488dfc;
    &.error {
      color: red;
      padding: 10px 0 10px 10px;
    }
  }
  @media (max-width: 400px) {
    .row {
      div[class^="col-"] {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  .terms-and-condition-disclaimer {
    font-size: 0.75rem;
    margin: 1rem 0;
    transition: opacity 0.2s, visibility 0.2s;
  }
  .hide {
    opacity: 0;
    transition: opacity 0, visibility 0.2s;
  }

  .row-crypto-value {
    min-height: 38px;
  }

  .col-amount-currency {
    & > div {
      display: flex;
      border: solid 2px #e6e6e6;
      border-radius: 5px;
      margin: 5px 0;
      background-color: white !important;
      .crypto-icon-container {
        border: 0;
      }
      input {
        margin: 0px;
        border: none;
        outline: 0px;
        width: 70%;
        height: 45px;
      }
      select {
        margin: 0px;
        border: none;
        outline: 0px;
        width: 30%;
        height: 45px;
        font-size: 16px !important;
        &:focus,
        &:active {
          box-shadow: none !important;
        }
      }
    }
  }

  .col-crypto-dropdown {
    .crypto-dropdown-container {
      display: flex;
      border: solid 2px #e6e6e6;
      border-radius: 5px;
      margin: 5px 0;
      background-color: white !important;
      &.disabled {
        background-color: #eaecef !important;
      }
      input {
        margin: 0px;
        border: none;
        outline: 0px;
        width: 70%;
        height: 45px;
      }
      select {
        margin: 0px;
        border: none;
        outline: 0px;
        flex: 1;
        height: 45px;
        &:focus,
        &:active {
          box-shadow: none !important;
        }
      }
    }
  }

  .crypto-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    border-radius: 5px;
    background-color: white;
    &.disabled {
      background-color: #eaecef !important;
    }
  }

  input {
    &:focus {
      outline-width: 0;
    }
  }

  .withdraw-crypto-selector {
    .crypto-icon-container {
      width: 40px;
    }
    .form-control {
      width: 30%;
      padding: 0;
    }
  }
}
