.fc-deposit-details {
	.details {
		width: 450px;
		padding: 20px 40px;

		background-color: #f7f7f7;
		.detail {
			display: flex;

			margin: 15px 0;

			justify-content: space-between;
			&__copy-btn {
				font-size: 10px;

				height: 24px;
				padding: 3px 10px;

				cursor: pointer;

				color: #3e86ff;
				border: 1px solid #3e86ff;
				border-radius: 4px;
			}
		}
	}
}