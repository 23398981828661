$base-color: #448aff;
$color-text-title: #2f2f2f;
$color-text-small: #6b6b6b;
$bg-color: #f8f8f8;
$bg-color-disabled: #f2f2f2;

.payment-methods {

  .img-title {
    height: 5rem;
  }

  .total-to-pay {
    font-size: 24px !important;
  }

  .fc-option {
    width: 100%;
    font-size: 14px;
    color: $color-text-title;
    border-radius: 0;
    height: 130px;
    padding: 0;

    > div {
      margin: 0 5px;
    }

    &:hover {
      background-color: $bg-color;
    }

    &.fc-option--active {
      background-color: $bg-color;
      color: $color-text-title;
    }

    &.fc-disabled {
      // background-color: $bg-color-disabled;
      cursor: default;

      &:hover {
        background-color: transparent;
      }
      .rad-button {
        cursor: default;

        .design {
          border: 1px solid darken($bg-color-disabled, 30%) !important;
        }
      }

      .text {
        .fc-option__text.payment-method-name,
        .fc-option__text.fee {
          color: #ccc;
        }
      }

      .image {
        opacity: 0.3 !important;
      }

      .tooltip-payment {
        a.question-mark {
          top: 12px;
          position: relative;
          height: 18px;
          background: #2e2e2e;
          display: block;
          border-radius: 15px;
          color: white;
          text-align: center;
          line-height: 18px;
          width: 18px;
          font-size: 14px;
          cursor: pointer;
          font-weight: bold;
          &:hover {
            color: white;
          }
        }
      }
    }

    > .row {
      width: 100%;
    }

    small {
      color: $color-text-small;
      font-size: 13px;
    }

    .rad-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;

      margin: 0;
      padding: 0;

      cursor: pointer;
      position: relative;

      input {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      .design {
        width: 20px;
        height: 20px;

        border: 1px solid $base-color;
        border-radius: 100%;

        position: relative;
      }

      .design::before {
        content: '';
        display: block;

        width: 16px;
        height: 16px;

        border-radius: inherit;

        position: absolute;
        transform: scale(0);
        transform-origin: center center;
        margin: 1px;
      }

      .design:before {
        background: $base-color;
        opacity: 0;
        transition: 0.3s;
      }

      input:checked + .design::before {
        opacity: 1;
        transform: scale(0.6);
      }
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;

      img {
        display: block;
        margin: 0;
        &.Rapipago {
          height: 1.5rem;
        }
        &.bankTransfer {
          height: 2rem;
        }
      }
    }

    .text {
      padding: 0;
      padding-left: 0.5rem;

      .fc-option__text.payment-method-name {
        color: #2f2f2f;
      }

      .col-12.fee {
        line-height: 1;

        .fc-option__text.fee {
          font-size: 11px;
        }

        .payment-disabled {
          font-size: 10px;
        }
      }
    }
  }

  .checkbox {
    --background: white;
    --border: #d1d6ee;
    --border-hover: #bbc1e1;
    --border-active: #448aff;
    --tick: #fff;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    span {
      margin-left: 0.5rem;
      font-size: 12px;
    }

    input,
    svg {
      width: 21px;
      height: 21px;
      display: block;
    }
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      outline: none;
      background: var(--background);
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      border-radius: 4px;
      transition: box-shadow 0.3s;
      box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
      &:hover {
        --s: 2px;
        --b: var(--border-hover);
      }
      &:checked {
        --b: var(--border-active);
      }
    }
    svg {
      pointer-events: none;
      fill: none;
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--stroke, var(--border-active));
      position: absolute;
      top: 0;
      left: 0;
      width: 21px;
      height: 21px;
      transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.bounce {
      --stroke: var(--tick);
      input {
        &:checked {
          --s: 11px;
          & + svg {
            animation: bounce 0.4s linear forwards 0.2s;
          }
        }
      }
      svg {
        --scale: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .payment-methods {
    .fc-option {
      width: 520px;
      padding: 0 50px;
      height: 80px;

      > div {
        margin-left: -15px;
        margin-right: -15px;
      }

      .col-7 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
      }

      .text,
      .image {
        padding-left: 2rem;
      }
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
