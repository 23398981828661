.fc-step {
  display: grid;

  // margin-top: 50px;
  margin-bottom: 20px;

  color: #2f2f2f;

  grid-gap: 16px;
  justify-items: center;

  .fc-input {
    width: 275px;
    height: 54px;
    padding: 0 7px;

    color: #000;
    border: solid 2px #e6e6e6;
    border-radius: 2px;
    outline: none;
    background-color: #fff;
    font-family: 'GoogleSansMedium';
    &.not-selected {
      color: #999;
    }
    &input {
      text-indent: 7px;
    }
    &::placeholder {
      color: #999;
    }
  }

  .fc-help-btn {
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    width: 28px;
    height: 28px;
    font-size: 18px;
    position: absolute;
    margin-left: -40px;
    margin-top: 12px;
  }

  .fc-option {
    user-select: none;
    display: flex;

    width: 380px;
    height: 80px;
    padding: 0 50px;

    cursor: pointer;

    color: #858585;
    border-radius: 8px;

    align-items: center;
    justify-content: space-between;
    &--active {
      color: #498cf9;
      background-color: #f7f7f7;
    }
    &__icon {
      height: 36px;
    }
    &__text {
      width: 200px;
    }
    &__left-space {
      margin-left: 20px;
    }
    input:disabled + img {
      filter: brightness(300%) grayscale(100%);
    }
  }

}
