@import '../../styles/globals/colors';

.account-input {
  width: 350px;
  height: 48px;
  margin: 1rem 0 !important;
}

.action-label {
  color: $dodger-blue;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;

  &.big {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0 !important;
  }

  &.big2 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px !important;
  }
}

.sign-up-title {
  font-weight: 500;
}

.subtitle {
  line-height: 1.6;
  flex-wrap: wrap;
  max-width: 500px;
  text-align: center;
  width: 100%;
  margin-top: 16px;
}

.text-height-20 {
  line-height: 20px !important;
}


@media (max-width: 348px) {
  .account-input {
    width: 100%;
  }
}