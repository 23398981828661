@import "../../styles/globals/colors";


.action-label {
  color: $dodger-blue;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;

  &.big {
    font-size: 14px;
  }
}

.enter-latamex {
  font-weight: 500;
}

/* This will be used in new button style
.login-button {
  font-size: 16px !important;
  padding-bottom: 13px !important;
  padding-top: 15px !important;
} */
