@import "../../../styles/globals/colors";

.custom-input {
  position: relative;

  .md-form.md-outline {
    label {
      font-family: RobotoRegular;
      font-size: 1rem;
      color: $medium-grey;
      top: 8px;
      left: 5px;
      &.active {
        font-family: RobotoLight;
        top: 8px;
        font-size: 0.75rem;
      }
    }
    input {
      height: 52px;
      padding: 13px 16px 11px 17px;
      font-size: 1rem;
      font-family: RobotoRegular;
      color: #000;
      font-weight: 500;
      border-radius: 12px;
    }
  }

  &__message {
    font-family: RobotoLight;
    font-size: 12px;
    left: 20px;
    position: absolute;
    top: 40px;
  }

  &--error {
    .md-form.md-outline {
      input,
      input:focus:not([readonly]) {
        border-color: $red;
        box-shadow: inset 0px 0px 0px 1px $red;
        border-bottom: 1px solid $red;

        & + label {
          color: $red;
        }
      }
    }

    .custom-input__message {
      color: $red;
      margin-top: 10px;
    }
  }

  &--success {
    .md-form.md-outline {
      input,
      input:focus:not([readonly]) {
        border-color: $green;
        box-shadow: inset 0px 0px 0px 1px $green;
        border-bottom: 1px solid $green;

        & + label {
          color: $green;
        }
      }
    }

    .custom-input__message {
      color: $green;
      margin-top: 15px;
    }
  }
}

.input-disabled {
  color: $medium-grey !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
