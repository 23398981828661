$radio-border: #CCC;
$radio-active-border: #448AFF;
$radio-center-fill: #448AFF;
$radio-label-color: #4a30ee;

.radio-wrapper {
  position: relative;

  .radio-icon {
    width: 34px;
    position: absolute;
    left: 25px;
    top: -5px;
  }
}

.radio-button {
  display: none;

  +.form-check-label {
    position: relative;
    padding-left: 10px;
    font-size: 14px;
    color: $radio-label-color;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 2px;

      width: 16px;
      height: 16px;

      background: #FFF;
      border: 1px solid $radio-border;
      border-radius: 50%;
    }

    &.mr-5 {
      margin-right: 0 !important;
    }
  }

  &:checked + .form-check-label {

    &::after {
      content: '';
      position: absolute;
      left: -17px;
      top: 5px;

      width: 10px;
      height: 10px;

      background: $radio-center-fill;
      border-radius: 50%;
    }

    &::before {
      border: 1px solid $radio-active-border;
    }
  }

  &.with-icon {
    +.form-check-label {
      padding-left: 50px;
    }
  }
}

