.check-description {
    line-height: 20px;
    margin-bottom: 32px !important;
}

.check-form {
    display: flex;
    flex-direction: column;
}

select {
    height: 48px !important;
    min-height: 48px !important;
}

button {
    margin-top: 8px !important;
}

.custom-input .md-form {
    margin-top: 16px !important;
    margin-bottom: 0 !important;
}

.margin-b-100 {
    margin-bottom: 100px !important;
}
