.select-custom {
  color: #9b9898;
}

.icon-option {
  width: 25px;
  height: 24px;
  margin-right: 5px;
}

.radio-input {
  display: table-cell;
  vertical-align: middle;
}

.radio-description {
  font-size: 12px;
  color: #cccccc;
  margin-left: 5px;
}

.selected {
  color: #448aff;
}

.wallet-container-input {
  max-width: 370px;
  margin: auto;
}

.custom-tooptil {
  width: 280px;
  text-align: left;
}

.terms-cond {
  padding-left: 25px;
  font-size: 12px;
}

.ar {
  text-align: right;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-top: 50px;
}

.mt-neg-10 {
  margin-top: -10px;
}

.mt-neg-12 {
  margin-top: -12px;
}

.mt-neg-14 {
  margin-top: -14px;
}

.mt-neg-16 {
  margin-top: -16px;
}

.mt-neg-20 {
  margin-top: -20px;
}

.mt-neg-24 {
  margin-top: -24px;
}

.mt-neg-28 {
  margin-top: -28px;
}

.check-detail {
  display: flex;
}

.check-detail .custom-control {
  display: flex;
  padding-left: 0;
}



.al-right {
  text-align: right;
}

.custom-col-detail {
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    padding-left: 30px;
  }
}

.text-height-20 {
  line-height: 20px !important;
}
