@import "../../../styles/globals/colors";

.email {
  font-family: GoogleSansMedium;
  font-size: 14px;
}

.logout {
  color: $dodger-blue;
  cursor: pointer;
  font-family: GoogleSansMedium;
  font-size: 14px;
}

.logout-container {
  padding-right: 50px;
}

@media (max-width: 767px) {
  .email {
    font-size: 12px;
  }

  .logout {
    font-size: 12px;
  }

  .logout-container {
    padding-right: 0px;
  }
}
