.container {
  line-height: 2;
  padding-left: 25px;
  @media (max-width: 480px) {
    padding-left: 15px;
  }
}

.text-import {
  font-size: 14px !important;
  line-height: 20px;
  padding: 0 30px;
}

.container-grid {
  width: 100%;
  font-size: 14px;
  text-align: left;
}

.grid-small {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-areas: "left-sector center-sector";
  @media (max-width: 480px) {
    grid-template-columns: 40% 60%;
  }
}

.grid-large {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  grid-template-areas: "left-sector center-sector right-sector";
  @media (max-width: 480px) {
    grid-template-columns: 100%;
    grid-template-areas:
      "left-sector"
      "center-sector"
      "right-sector";
  }
}

.column-title {
  grid-area: left-sector;
}
.column-detail {
  grid-area: center-sector;
  color: #070d10;
  font-size: 14px !important;
}
.column-tools {
  grid-area: right-sector;
}
.line-small {
  font-size: 13px;
  line-height: 1.5;
  padding-top: 5px;
  @media (max-width: 480px) {
    padding-top: 0;
  }
}
