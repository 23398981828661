.code-validation {
  text-align: center;
  margin-bottom: 23px;
  .custom-input {
    width: 49px;
    display: inline-block;
    height: 52px;
    margin: 0 13px;
    .md-form.md-outline input {
      border: solid 1px #afafaf;
      border-radius: 12px;
      font-size: 30px;
      padding: 0;      
    }
  }
}

.code-validation.code-valid .custom-input .md-form.md-outline input {
  border: solid 1px #afa;
}

.code-validation.code-invalid .custom-input .md-form.md-outline input {
  border: solid 1px #faa;
}
