.confirm-form {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: bold;
  .label-container {
    left: 100%;
    bottom: 20px;
  }
  label {
    position: absolute;
    right: 30px;
    top: 20px;
  }
  input,
  select {
    width: 100%;
    margin: 5px 0;
    height: 50px;
    border: 2px solid #e7e7e7;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
  }
  input.currency {
    padding-right: 48px;
  }
  button {
    width: 100%;
    margin: 1rem 0;
    height: 50px;
  }
  .quote-value {
    padding: 10px 0 10px 10px;
    display: block;
  }
  small {
    color: #488dfc;
    &.error {
      color: red;
    }
  }
  @media (max-width: 400px) {
    .row {
      div[class^="col-"] {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.price-desc {
  font-size: 12px;
  line-height: 1.4;
}

.trns-det {
  font-size: 16px;
  font-weight: 500;
  color: #070d10;
  line-height: 24px !important;
}

.lh-24 {
  line-height: 24px !important;
}
