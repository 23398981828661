.custom-primary {
  padding: 8px 55px !important;
  color: #FFF !important;
  background-color: #488dfc !important;
  text-transform: capitalize;
  font-weight: 500;
  box-shadow: none;

  &.btn-default:not([disabled]):not(.disabled):active,
  &:hover,
  &:focus,
  &:active {
    background-color: #488dfc !important;
    box-shadow: none !important;
  }
}

.custom-secondary {
  padding: 8px 55px !important;
  background: #F7F7F7 !important;
  color: #448AFF !important;
  box-shadow: none;
  text-transform: capitalize;
  font-size: 13px !important;
  font-weight: 500;

  &.btn-default:not([disabled]):not(.disabled):active,
  &:hover,
  &:focus,
  &:active {
    background-color: #F7F7F7 !important;
    box-shadow: none !important;
  }
}

.full-width {
  width: 100%;
}
