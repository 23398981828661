$box-color: #fff;

.layout {
  display: flex;
  flex-direction: column;

  .logo {
    width: 180px;
    margin: 0 auto;
  }

  .margin-container-bottom-12 {
    margin-bottom: 12px;
  }

  .margin-container-bottom-150 {
    margin-bottom: 150px;
  }


  .box {
    position: relative;

    width: 60%;
    max-width: 700px;
    height: 500px;
    padding: 30px 40px;
    margin: 20px auto;

    background: $box-color;
    border-radius: 3px;

    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.16);

    .back-button {
      position: absolute;
      left: 30px;
      top: 30px;

      border-width: 0;
      box-shadow: none;
      background: transparent;

      i {
        color: #2f2f2f !important;
        font-size: 30px;
      }

      &:active,
      &:hover,
      &:focus {
        border-width: 0;
        box-shadow: none;
        background: transparent;
        outline: none;
      }
    }
  }

  .loader-container {
    min-height: 400px;
  }

  &.no-box .box {
    background: transparent;
    box-shadow: none;
  }

  .kyc-iframe-container {
    margin: 0 auto;
    // height: 610px;
    margin: -1.25rem; // to counter out the undesired padding card effect in iframe
    & > iframe {
      border: 0 none;
    }
  }
}
