@font-face {
  font-family: 'RobotoRegular';
  src: url('./fonts/roboto/Roboto-Regular-webfont.woff') format('woff2');
}

@font-face {
  font-family: 'RobotoLight';
  src: url('./fonts/roboto/Roboto-Light-webfont.woff') format('woff2');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('./fonts/roboto/Roboto-Bold-webfont.woff') format('woff2');
}

@font-face {
  font-family: 'GoogleSansRegular';
  src: url('./fonts/google-sans/GoogleSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GoogleSansItalic';
  src: url('./fonts/google-sans/GoogleSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'GoogleSansMedium';
  src: url('./fonts/google-sans/GoogleSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'GoogleSansMediumItalic';
  src: url('./fonts/google-sans/GoogleSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'GoogleSansBold';
  src: url('./fonts/google-sans/GoogleSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'GoogleSansBoldItalic';
  src: url('./fonts/google-sans/GoogleSans-BoldItalic.ttf') format('truetype');
}
