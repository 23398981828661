@import "../../styles/globals/colors";

.error-code {
  color: $dusty-gray;
  font-size: 12px;
  font-weight: 500;
}

.error-description {
  font-family: GoogleSansRegular;
  font-size: 18px !important;
  text-align: center;
  width: 100%;
}

.error-link {
  color: $dodger-blue;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: $dodger-blue;
  }
}

.error-title {
  font-size: 24px;
  font-weight: 500;
}

.languages-icon {
  margin-right: 5px;
  margin-top: 15px;
}

.toggle {
  padding: 10px !important;
  width: 52px;
}
