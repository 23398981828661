.confirm-success {
  .bank-detail {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    width: 70%;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    background-color: #f9f9f9;
    & > div.icon {
      grid-column: 1;
      text-align: center;
    }
    & > div.my-auto {
      grid-column: 2 / 4;
    }
  }

  .copyButton {
    font-family: GoogleSansMedium;
    font-size: 0.75rem;
    color: #3783ff;
    padding: 3px 5px;
    cursor: pointer;

    border: 1px solid #3783ff;
    border-radius: 5px;
    margin-left: 1rem;
  }
}
