.logout-row {
  display: flex;
  justify-content: flex-end;
  margin-right: 0px;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 1;
}

@media (max-width: 767px) {
  .logout-row {
    justify-content: center;
    top: 5px;
  }
}
