$main-blueviolet: #4a30ee;
$light-blueviolet: #f2f2f9;
$dark-grey: #2f2f2f;
$medium-grey: #989898;
$red: #e83b3b;
$green: #58c068;
$golden: #f0ba00;
$dark-text: #2f2f2f;
$dodger-blue: #448aff;
$stellar-button: #488dfc;
$blue-link: #007bff;
$dusty-gray: #9b9b9b;

// binance colors
$color_steel_gray_approx: #212833;
$color_corn_approx: #f0b90b;
$color_bright_sun_approx: #fbda3c;
$color_corn_30_approx: rgba(240, 185, 10, 0.3);
$color_gallery_approx: #eaecef;
$color_regent_gray_approx: #848e9c;
